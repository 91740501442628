import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";
import "./css/bootstrap.css";
import "./vendor/owl-carousel/css/owl.carousel.min.css";
import "./css/mobster.css";
import "./css/maicons.css";
import "./css/dialog.css";
import "./vendor/animate/animate.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
