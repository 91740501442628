import React from "react";
import hero_mini from "../img/hero_mini.svg";

const About = () => {
  return (
    <main className="bg-light">
      <div
        className="page-hero-section bg-image hero-mini"
        style={{ backgroundImage: `url(${hero_mini})` }}
      >
        <div className="hero-caption">
          <div className="container fg-white h-100">
            <div className="row justify-content-center align-items-center text-center h-100">
              <div className="col-lg-6">
                <h3 className="mb-4 fw-medium">About Us</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-section pt-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="card-page">
                <h5 className="fg-primary">
                  <b>Introduction</b>
                </h5>
                <hr />
                <p>
                  Charge BI is a white-label management platform developed in
                  2023 to provide a full solution for Electric Vehicle Charging
                  Station Operators to efficiently monitor, control and optimize
                  their electric vehicle charging infrastructure.
                </p>
              </div>
              <div className="card-page mt-3">
                <h5 className="fg-primary">
                  <b>Our Vision</b>
                </h5>
                <hr />

                <div className="row justify-content-center">
                  <div className="col-lg-311 py-3">
                    <p>
                      Driving the future of sustainable transportation through
                      cutting-edge technology.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-page mt-3">
                <h5 className="fg-primary">
                  <b>Our Mission</b>
                </h5>
                <hr />

                <div className="row justify-content-center">
                  <div className="col-lg-311 py-3">
                    <p>
                      To accelerate the adoption of electric vehicles by
                      providing innovative, scalable, and reliable EVSE
                      management solutions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default About;
