import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";
import logo from "../img/Logo_Resim.png";

const Navbar = () => {
  const navigate = useNavigate();

  const handleScrollClick = (section) => {
    navigate("/", { replace: true });
    setTimeout(() => {
      scroller.scrollTo(section, {
        duration: 100,
        delay: 100,
        smooth: true,
        offset: -70, // navbar height adjustment
      });
    }, 100);
  };

  const handleRequestDemoClick = () => {
    navigate("/", { replace: true });
    setTimeout(() => {
      scroller.scrollTo("contact", {
        duration: 100,
        delay: 100,
        smooth: true,
        offset: -70, // navbar height adjustment
      });
    }, 100);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark navbar-floating">
      <div className="container">
        <RouterLink className="navbar-brand" to="/">
          <img src={logo} alt="Logo" width="40" />
        </RouterLink>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarToggler"
          aria-controls="navbarToggler"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarToggler">
          <ul className="navbar-nav ml-auto mt-3 mt-lg-0">
            <li className="nav-item">
              <RouterLink className="nav-link" to="/">
                <b>Home</b>
              </RouterLink>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                onClick={() => handleScrollClick("solutions")}
                style={{ cursor: "pointer" }}
              >
                <b>Solutions</b>
              </a>
            </li>
            <li className="nav-item">
              <RouterLink className="nav-link" to="/about">
                <b>About Us</b>
              </RouterLink>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                onClick={() => handleScrollClick("partnership")}
                style={{ cursor: "pointer" }}
              >
                <b>Partnership</b>
              </a>
            </li>
            <li className="nav-item">
              <RouterLink className="nav-link" to="/contact-us">
                <b>Contact Us</b>
              </RouterLink>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                onClick={() => handleScrollClick("faq")}
                style={{ cursor: "pointer" }}
              >
                <b>FAQ</b>
              </a>
            </li>
          </ul>
          <div className="ml-auto my-2 my-lg-0">
            <button
              className="btn btn-primary rounded-pill"
              onClick={handleRequestDemoClick}
            >
              Request Demo
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
