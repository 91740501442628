import React from "react";
import { Link, Element } from "react-scroll";

import png1 from "../img/app_preview_1.png";
import png2 from "../img/icons/payment.png";
import concept from "../img/icons/customizable.png";
import png5 from "../img/app_preview_2.png";
import png6 from "../img/app_preview_6.png";

import svg0 from "../img/bg_hero_2.svg";
import rocket from "../img/icons/rocket.svg";
import promotion from "../img/icons/promotion.svg";
import testimony from "../img/icons/testimony.svg";
import coins from "../img/icons/coins.svg";
import support from "../img/icons/support.svg";
import laptop from "../img/icons/laptop.svg";
import illustration_contact from "../img/illustration_contact.svg";
import ContactForm from "../compenents/ContactForm";

const Home = () => {
  return (
    <div>
      <div
        className="page-hero-section bg-image hero-home-2"
        style={{ backgroundImage: `url(${svg0})` }}
      >
        <div className="hero-caption">
          <div className="container fg-white h-100">
            <div className="row align-items-center h-100">
              <div className="col-lg-6 wow fadeInUp">
                <div className="badge badge-soft mb-2"></div>
                <h1 className="mb-4 fw-normal" style={{ fontSize: "270%" }}>
                  <b>Revolutionize Your Business with ChargeBI CSMS Solution</b>
                </h1>
                <p className="mb-4">
                  Empower your EV Charging Operations with Our White-Label CSMS
                </p>

                <Link
                  to="contact"
                  smooth={true}
                  duration={100}
                  className="btn btn-dark"
                  style={{ cursor: "pointer" }}
                >
                  Get Started Today
                </Link>
              </div>
              <div className="col-lg-6 d-none d-lg-block wow zoomIn">
                <div className="img-place mobile-preview shadow floating-animate">
                  <img src={png1} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-section no-scroll">
        <div className="container">
          <h2 className="text-center wow fadeIn">
            <b>Key Benefits</b>
          </h2>

          <div className="row justify-content-center mt-5">
            <div className="col-lg-10">
              <div className="row justify-content-center">
                <div className="col-md-6 col-lg-4 py-3 wow fadeInLeft">
                  <div className="card card-body border-0 text-center shadow pt-5">
                    <div className="svg-icon mx-auto mb-4">
                      <img src={png2} alt="" />
                    </div>
                    <h5 className="fg-gray">
                      <b>Secure Operation</b>
                    </h5>
                    <p className="fs-small">
                      ChargeBI Mobile Applications and Dashboards are secured by
                      pentest tests. Cyber ​​security tests are carried out
                      before delivery to the customer.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 py-3 wow fadeInUp">
                  <div className="card card-body border-0 text-center shadow pt-5">
                    <div className="svg-icon mx-auto mb-4">
                      <img src={concept} alt="" />
                    </div>
                    <h5 className="fg-gray">
                      <b>Scalable and Flexible</b>
                    </h5>
                    <p className="fs-small">
                      Tailor our solution to fit your business needs, no matter
                      the size with user-friendly interface
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 py-3 wow fadeInRight">
                  <div className="card card-body border-0 text-center shadow pt-5">
                    <div className="svg-icon mx-auto mb-4">
                      <img src={concept} alt="" />
                    </div>
                    <h5 className="fg-gray">
                      <b>Powerful Concept</b>
                    </h5>
                    <p className="fs-small">
                      Supporting OCPP version 1.6j and 2.0 with all functions
                      <br /> Interoperability support with ChargeBI Gateway
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 py-3 wow fadeInRight">
                  <div className="card card-body border-0 text-center shadow pt-5">
                    <div className="svg-icon mx-auto mb-4">
                      <img src={concept} alt="" />
                    </div>
                    <h5 className="fg-gray">
                      <b>Reliable Performance</b>
                    </h5>
                    <p className="fs-small">
                      Ensure uptime and efficiency with our robust
                      infrastructure.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-section no-scroll">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 wow fadeIn">
              <div className="img-place">
                <img src={png5} alt="" />
              </div>
            </div>
            <div className="col-lg-5 pl-lg-5 wow fadeInUp">
              <h2 className="mb-4">
                <b>Maximize your return of investments</b>
              </h2>
              <p className="mb-4">
                ChargeBI helps you to eliminate the challenges and complexities
                of managing electric vehicle charging infrastructure enhance
                user experience and maximize your profit with it's developed and
                robust structure.
              </p>
            </div>
          </div>
        </div>
      </div>
      <h2 className="text-center wow fadeIn">
        <b>Key Features</b>
      </h2>
      <div className="page-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 py-3">
              <div className="iconic-list">
                <div className="iconic-item wow fadeInUp">
                  <div className="iconic-content">
                    <h5>
                      <b>Comprehensive Dashboard</b>
                    </h5>
                    <p className="fs-small">
                      Instant access, centralized control and monitoring of all
                      charging stations.
                    </p>
                  </div>
                  <div className="iconic-md iconic-text bg-warning fg-white rounded-circle">
                    <span className="mai-analytics"></span>
                  </div>
                </div>
                <div className="iconic-item wow fadeInUp">
                  <div className="iconic-content">
                    <h5>
                      <b>Billing & Payments</b>
                    </h5>
                    <p className="fs-small">
                      Integrated billing system with multiple payment options.
                    </p>
                  </div>
                  <div className="iconic-md iconic-text bg-info fg-white rounded-circle">
                    <span className="mai-shield-checkmark"></span>
                  </div>
                </div>
                <div className="iconic-item wow fadeInUp">
                  <div className="iconic-content">
                    <h5>
                      <b>API Integration</b>
                    </h5>
                    <p className="fs-small">
                      {" "}
                      Easy integration with third-party services and
                      applications. (Including GIB and EPDK integrations)
                    </p>
                  </div>
                  <div className="iconic-md iconic-text bg-indigo fg-white rounded-circle">
                    <span className="mai-desktop-outline"></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 py-3 wow zoomIn">
              <div className="img-place mobile-preview shadow">
                <img src={png6} alt="" />
              </div>
            </div>
            <div className="col-lg-4 py-3">
              <div className="iconic-list">
                <div className="iconic-item wow fadeInUp">
                  <div className="iconic-md iconic-text bg-warning fg-white rounded-circle">
                    <span className="mai-speedometer-outline"></span>
                  </div>
                  <div className="iconic-content">
                    <h5>
                      <b>Remote Diagnostics</b>
                    </h5>
                    <p className="fs-small">
                      Proactive maintenance and troubleshooting with remote
                      access.
                    </p>
                  </div>
                </div>
                <div className="iconic-item wow fadeInUp">
                  <div className="iconic-md iconic-text bg-success fg-white rounded-circle">
                    <span className="mai-aperture"></span>
                  </div>
                  <div className="iconic-content">
                    <h5>
                      <b>Reporting & Analytics</b>
                    </h5>
                    <p className="fs-small">
                      Detailed reports and insights to drive business decisions.
                    </p>
                  </div>
                </div>
                <div className="iconic-item wow fadeInUp">
                  <div className="iconic-md iconic-text bg-indigo fg-white rounded-circle">
                    <span className="mai-stats-chart-outline"></span>
                  </div>
                  <div className="iconic-content">
                    <h5>
                      <b>Smart Charging</b>
                    </h5>
                    <p className="fs-small">
                      Load management and energy optimization features.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />
      <Element name="solutions">
        <div className="page-section bg-dark fg-white">
          <div className="container">
            <h1 className="text-center">
              <b>Why Choose ChargeBI?</b>
            </h1>

            <div className="row justify-content-center mt-5">
              <div className="col-md-6 col-lg-3 py-3">
                <div className="card card-body border-0 bg-transparent text-center wow zoomIn">
                  <div className="mb-3">
                    <img src={rocket} alt="" />
                  </div>
                  <p className="fs-large">
                    <b>Fast Status Control</b>
                  </p>
                  <p className="fs-small fg-grey">
                    View and check charging station status in real time.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 py-3">
                <div
                  className="card card-body border-0 bg-transparent text-center wow zoomIn"
                  data-wow-delay="200ms"
                >
                  <div className="mb-3">
                    <img src={testimony} alt="" />
                  </div>
                  <p className="fs-large">
                    <b>User Friendly Dashboard</b>
                  </p>
                  <p className="fs-small fg-grey">
                    Manage the panel with user-friendly design.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 py-3">
                <div
                  className="card card-body border-0 bg-transparent text-center wow zoomIn"
                  data-wow-delay="400ms"
                >
                  <div className="mb-3">
                    <img src={promotion} alt="" />
                  </div>
                  <p className="fs-large">
                    <b>Check Your Transactions</b>
                  </p>
                  <p className="fs-small fg-grey">
                    Control your transactions easily.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 py-3">
                <div
                  className="card card-body border-0 bg-transparent text-center wow zoomIn"
                  data-wow-delay="600ms"
                >
                  <div className="mb-3">
                    <img src={coins} alt="" />
                  </div>
                  <p className="fs-large">
                    <b>Customer 360</b>
                  </p>
                  <p className="fs-small fg-grey">
                    Manage and view customer profiles in detail.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 py-3">
                <div
                  className="card card-body border-0 bg-transparent text-center wow zoomIn"
                  data-wow-delay="800ms"
                >
                  <div className="mb-3">
                    <img src={support} alt="" />
                  </div>
                  <p className="fs-large">
                    <b>Define User Roles</b>
                  </p>
                  <p className="fs-small fg-grey">
                    Manage users by defining user roles.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 py-3">
                <div
                  className="card card-body border-0 bg-transparent text-center wow zoomIn"
                  data-wow-delay="1000ms"
                >
                  <div className="mb-3">
                    <img src={laptop} alt="" />
                  </div>
                  <p className="fs-large">
                    <b>Map and Location</b>
                  </p>
                  <p className="fs-small fg-grey">
                    View charging stations on the map.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>

      <hr />

      <Element name="contact" className="page-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 py-3 mb-5 mb-lg-0">
              <div className="img-place w-lg-75 wow zoomIn">
                <img src={illustration_contact} alt="" />
              </div>
            </div>
            <div className="col-lg-5 py-3">
              <Element name="partnership">
                <h1 className="wow fadeInUp">
                  For More Information <br />
                  Please contact us
                </h1>
              </Element>

              <ContactForm />
            </div>
          </div>
        </div>
      </Element>
    </div>
  );
};

export default Home;
