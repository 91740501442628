import React from "react";
import hero_mini from "../img/hero_mini.svg";

const Contact = () => {
  return (
    <div className="bg-light">
      <div
        className="page-hero-section bg-image hero-mini"
        style={{ backgroundImage: `url(${hero_mini})` }}
      >
        <div className="hero-caption">
          <div className="container fg-white h-100">
            <div className="row justify-content-center align-items-center text-center h-100">
              <div className="col-lg-6">
                <h3 className="mb-4 fw-medium">Contact</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 my-3 wow fadeInUp">
              <div className="card-page">
                <div className="row row-beam-md">
                  <div className="col-md-4 text-center py-3 py-md-2">
                    <i className="mai-location-outline h3"></i>
                    <p className="fg-primary fw-medium fs-vlarge">Location</p>
                    <p className="mb-0">Üsküdar Altunizade, İST 34050</p>
                  </div>
                  <div className="col-md-4 text-center py-3 py-md-2">
                    <i className="mai-call-outline h3"></i>
                    <p className="fg-primary fw-medium fs-vlarge">Contact</p>
                    <p className="mb-1">+90 530 0223552</p>
                    <p className="mb-0">+90 536 3400340</p>
                  </div>
                  <div className="col-md-4 text-center py-3 py-md-2">
                    <i className="mai-mail-open-outline h3"></i>
                    <p className="fg-primary fw-medium fs-vlarge">Email</p>
                    <p className="mb-1">support@chargebi.com</p>
                    <p className="mb-0">admin@chargebi.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
