import React from "react";
import { Element } from "react-scroll";

const Footer = () => {
  return (
    <Element name="faq">
      <div className="page-footer-section bg-dark fg-white">
        <div className="container">
          <div className="row mb-5 py-3">
            <div className="col-sm-6 col-lg-2 py-3">
              <h5 className="mb-3">Pages</h5>
              <ul className="menu-link">
                <li>
                  <a href="#" className="">
                    Features
                  </a>
                </li>
                <li>
                  <a href="#" className="">
                    Customers
                  </a>
                </li>
                <li>
                  <a href="#" className="">
                    Pricing
                  </a>
                </li>
                <li>
                  <a href="#" className="">
                    GDPR
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-sm-6 col-lg-2 py-3">
              <h5 className="mb-3">Company</h5>
              <ul className="menu-link">
                <li>
                  <a href="#" className="">
                    About
                  </a>
                </li>
                <li>
                  <a href="#" className="">
                    Team
                  </a>
                </li>

                <li>
                  <a href="#" className="">
                    HIRING!
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-lg-4 py-3">
              <h5 className="mb-3">Contact</h5>
              <ul className="menu-link">
                <li>
                  <a href="#" className="">
                    Contact Us
                  </a>
                </li>
                <li>
                  <a href="#" className="">
                    Office Location
                  </a>
                </li>
                <li>
                  <a href="#" className="">
                    info@chargebi.com
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-lg-4 py-3">
              <h5>Get some offers, news, or update features of application</h5>

              <div className="mt-4">
                <a href="#" className="btn btn-fab btn-primary fg-white">
                  <span className="mai-logo-facebook"></span>
                </a>
                <a href="#" className="btn btn-fab btn-primary fg-white">
                  <span className="mai-logo-twitter"></span>
                </a>
                <a href="#" className="btn btn-fab btn-primary fg-white">
                  <span className="mai-logo-instagram"></span>
                </a>
                <a href="#" className="btn btn-fab btn-primary fg-white">
                  <span className="mai-logo-google"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default Footer;
