import React, { useState } from 'react'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

const ContactForm = () => {
  const url = 'https://charge3bi.duckdns.org:3000/email/send'
  const [emailData, setEmailData] = useState({
    email: '',
    subject: 'contact',
    text: '',
    fullname: ''
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)

  async function sendEmail () {
    setLoading(true)
    const headers = {
      'Content-Type': 'application/json'
    }
    try {
      await axios.post(url, emailData, { headers })
      setSuccess(true)
    } catch (error) {
      if (error.code === 'ERR_BAD_REQUEST') {
        setError('Please fill in the blank fields before submitting the form')
      } else {
        setError(
          'An error occurred while sending the email. \nPlease try again.'
        )
      }
    } finally {
      setLoading(false)
    }
  }

  const handleInputChange = e => {
    const { id, value } = e.target
    setEmailData(prevState => ({
      ...prevState,
      [id]: value
    }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    await sendEmail()
  }

  const handleClosePopup = () => {
    setError('')
    setSuccess(false)
  }
  return (
    <div className='contact-form-container'>
      <form className='mt-5' onSubmit={handleSubmit}>
        <div className='form-group wow fadeInUp'>
          <label htmlFor='fullname' className='fw-medium fg-grey'>
            Fullname
          </label>
          <input
            type='text'
            className='form-control'
            id='fullname'
            value={emailData.fullname}
            onChange={handleInputChange}
          />
        </div>

        <div className='form-group wow fadeInUp'>
          <label htmlFor='email' className='fw-medium fg-grey'>
            Email
          </label>
          <input
            type='email'
            className='form-control'
            id='email'
            value={emailData.email}
            onChange={handleInputChange}
          />
        </div>

        <div className='form-group wow fadeInUp'>
          <label htmlFor='text' className='fw-medium fg-grey'>
            Message
          </label>
          <textarea
            rows='6'
            className='form-control'
            id='text'
            value={emailData.text}
            onChange={handleInputChange}
          ></textarea>
        </div>

        <div className='form-group mt-4 wow fadeInUp'>
          <Button type='submit' variant='contained' color='primary'>
            Send Message
          </Button>
        </div>
      </form>

      <Dialog open={loading} onClose={handleClosePopup}>
        <DialogContent
          dividers
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <DialogTitle>Sending Email</DialogTitle>
          <DialogContentText>Please wait...</DialogContentText>
          <CircularProgress />
        </DialogContent>
      </Dialog>

      <Dialog open={!!error} onClose={handleClosePopup}>
        <DialogContent
          dividers
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <DialogTitle>Error</DialogTitle>
          <DialogContentText>{error}</DialogContentText>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={handleClosePopup} color='primary' autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={success} onClose={handleClosePopup}>
        <DialogContent
          dividers
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <DialogTitle>Success</DialogTitle>
          <DialogContentText>Email sent successfully!</DialogContentText>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={handleClosePopup} color='primary' autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ContactForm
